import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { CheckIcon } from "@heroicons/react/outline"

const StudioPage = () => {
  const features = [
    {
      name: "丰富多样组件",
      description: "覆盖常见自动化场景，比如鼠标键盘操作、网页操作、数据处理",
    },
    {
      name: "BPMN工作流",
      description: "流程图遵循bpmn2.0标准，进行业务建模和流程挖掘",
    },
    {
      name: "智能行业组件",
      description: "基于行业场景的智能组件，如Excel表格校验等，开箱即用",
    },
    {
      name: "智能AI组件",
      description: "丰富的OCR、NLP等AI组件，拖拽即可使用",
    },
    {
      name: "自定义组件",
      description: "灵活创建自定义的扩展组件，覆盖更多自动化业务场景",
    },
    {
      name: "异常处理",
      description: "支持流程的异常重试、异常通知，提高运行时的稳定性",
    },
    {
      name: "支持多系统",
      description: "支持Windows、信创系统运行，支持公有云、私有云",
    },
    {
      name: "Python脚本扩展",
      description: "根据定制的需求，灵活导入Python脚本，实现自动化全覆盖",
    },
  ]

  return (
    <Layout>
      <Seo title="DWRPA设计器" />
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">DWRPA设计器</span>
                <span className="block"></span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-indigo-200">
                轻松设计RPA流程图，BPMN工作流标准
              </p>
            </div>
          </div>
          <div className="py-8 lg:py-12">
            <StaticImage
              className="rounded-md object-cover object-left-top"
              src="../../images/设计器.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900">功能特点</h2>
          <p className="mt-4 text-lg text-gray-500"></p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-4 lg:gap-x-8">
          {features.map(feature => (
            <div key={feature.name} className="relative">
              <dt>
                <CheckIcon
                  className="absolute h-6 w-6 text-green-500"
                  aria-hidden="true"
                />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </Layout>
  )
}

export default StudioPage
